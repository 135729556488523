<template>
    <TransitionRoot appear :show="modalIsOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>
  
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all text-center"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900 -mt-3"
                >
                  {{ modalTitle }}
                </DialogTitle>
                <div class="mt-2">
                  <div class="text-sm text-gray-500"> <!-- content here -->
                    <slot name="content" />
                  </div>
                </div>
  
                <div class="mt-4">
                  <slot name="actions" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  
  <script setup>
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  } from "@headlessui/vue";
  import { ref, defineProps, defineEmits, watch } from "vue";
  
  const emit = defineEmits()
  const props = defineProps({
    isOpen: { type: Boolean },
    modalTitle: String,
  })
  
  const modalIsOpen = ref(props.isOpen)
  
  function closeModal() {
    modalIsOpen.value = false
    emit('modalIsClose');
  }
  
  watch(() => props.isOpen, (val) => {
    modalIsOpen.value = val
  })
  
  </script>
  
  