<template>
  <div class="profile-page">
    <ModalComponent
      :is-open="showConfirm"
      :modal-title="`Para que não hajam compras repetidas, por favor, confirme se adiquiriu o item: ${itemName}`"
      @modalIsClose="closeModal()"
    >
      <template #actions>
        <div class="flex justify-between w-full">
          <BaseButton 
            :label="'SIM'" 
            :icon="'fa-solid fa-check'" 
            @click="purchaseItem()"
          />
          <BaseButton 
            style="background-image: linear-gradient(to right, #d32f2f, #ef5350) !important;"
            :label="'NÃO'" 
            :icon="'fa-solid fa-xmark'" 
            @click="closeModal()"
          />
        </div>
      </template>

    </ModalComponent>
    <section>
      <div class="container mx-auto">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg  "
        >
          <div class="px-1">
            <div class="flex flex-wrap justify-center">
              <div class="w-full px-4 lg:order-2 flex justify-center">
                <img
                  style="width: 100px; height: 100px"
                  :src="
                    props.category.image ||
                    'https://classic.exame.com/wp-content/uploads/2016/09/size_960_16_9_acessorios-de-cozinha4.jpg'
                  "
                  class="shadow-xl rounded-full align-middle border-none max-w-150-px mb-3"
                />
              </div>
            </div>

            <div class="mt-2 border-t border-blueGray-200 text-center">
              <div class="flex flex-wrap justify-center">
                <div class="w-full">
                  <div
                    class="md:text-start text-blueGray-600 mt-4 mb-4 overflow-y-auto max-h-60 scroll rounded p-1"
                  >
                    <table class="w-full">
                      <thead>
                        <tr>
                          <HeadTableModal :text-center="false" class="rounded">
                            <span class="text-lg text-blueGray-700"> Item </span>
                          </HeadTableModal> 
                          <HeadTableModal :text-center="false" class="rounded"> 
                            <span class="text-lg text-blueGray-700"> Link </span>
                          </HeadTableModal>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(row, index) in props.category.items"
                          :key="index"
                          class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9"
                          :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
                        >
                          <td
                            class="text-left whitespace-nowrap text-gray-900 min-w-[8px] pt-2 pb-2"
                          >
                            <div class="pl-4">
                              <span>
                                {{ row.name.substring(0, 15) + "..." }}
                              </span>
                            </div>
                          </td>
                          <td class="text-left whitespace-nowrap text-gray-900 cursor-pointer min-w-[8px] pt-2 pb-2">
                            <div class="text-center">
                              <a @click="showModal(row.name, row.id)" :href="row.link" target="_blank" rel="noopener noreferrer">
                                <i :class="row.purchase ? 'line-through text-gray-600' : 'text-blue-400'"> {{ row.link.substring(0, 25) + "..." }} </i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import HeadTableModal from './../TableHeadField.vue';
import ModalComponent from './ModalComponent.vue';
import BaseButton from '../BaseButton.vue';
import confetti from 'canvas-confetti'
import { defineProps, ref } from 'vue';
import { http } from '@/http';
import store from '@/store';

let itemName = ref(undefined)
let itemId = ref(null)

const showConfirm = ref(false)

const props = defineProps({
  category: [Object, Array]
})

const showModal = (name, id) => {
  itemName = name
  itemId = id
  showConfirm.value = true
}

const closeModal = () => {
  itemId = null
  showConfirm.value = false
}

function launchConfetti() {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 }
  });
}

function purchaseItem() {
  http.put(`purchased/${itemId}`).then(res => {
    if(res.status == 200) {
      store.dispatch('getItems')
    }
  });
  
  launchConfetti()
  closeModal()
}

</script>

<style scoped>
a {
  text-decoration: none;
}
</style>