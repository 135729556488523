<!-- BlocksComponent.vue -->
<template>
  <div class="text-center p-5">
    <div class="images-container flex justify-center items-center space-x-[-10px] pt-10 pb-10 flex-wrap">
      <img
        :src="this.firtsImage"
        alt="Image 1"
        class="circular-image w-32 h-32 md:w-56 md:h-56 rounded-full object-cover border-2 border-white"
      />
      <img
        :src="this.secondImage"
        alt="Image 2"
        class="circular-image w-32 h-32 md:w-56 md:h-56 rounded-full object-cover border-2 border-white"
      />
    </div>
    <div class="pb-10 flex flex-col items-center">
      <h1 class="mt-5 text-6xl md:text-7xl title font-bold w-full">Vamos nos casar</h1>
      <h3 class="mt-4 text-xl md:text-2xl text-gray-600 w-5/6 md:w-3/6 text-center text-color">
        Estamos muito felizes e queremos compartilhar com vocês toda nossa alegria 
      </h3>
    </div>

    <div class="mt-12 flex flex-col items-center">
      <p class="text-sm md:text-base leading-relaxed w-5/6 md:w-4/6 font-bold">
        Estamos contando as horas para este dia mágico e cheio de amor, e é com imensa alegria que dividímos cada detalhe com vocês. Obrigado por fazerem parte desse momento tão especial!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocksComponent",
  data() {
    return {
      firtsImage: require('./../assets/eve.webp'),
      secondImage: require('./../assets/me.jpeg'),
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'Heart';
  src: url('./../assets/fonts/wordHeart.ttf');
}
.title {
  font-family: 'convite';
  color: #bb966c;
}
.text-color {
  color: #bb966c;
}
.heart {
  font-family: 'Heart';
}

</style>