import { createStore } from 'vuex'
import { http } from '@/http'

export default createStore({
  state: {
    items: []
  },
  mutations: {
    setItems(state, newItems) {
      state.items = newItems;
    }
  },
  actions: {
    async getItems({ commit }) {
      await http.get('list')
        .then(res => {
          commit('setItems', res.data)
        })
    }
  },
  getters: {
    itemsArray: state => state.items
  },
  modules: {
  }
})
