<template>
  <div class="flex flex-wrap justify-center items-center space-x-2 md:space-x-4 pb-5 pt-5">
    <div class="bg-white text-black p-2 md:p-4 rounded-lg shadow-lg text-center min-w-14 md:min-w-28 txt shad">
      <p class="text-2xl md:text-4xl font-bold">{{ days }}</p>
      <p class="text-sm md:text-lg">Dias</p>
    </div>
    <div class="text-2xl md:text-4xl font-bold">:</div>
    
    <div class="bg-white text-black p-2 md:p-4 rounded-lg shadow-lg text-center min-w-14 md:min-w-28 txt shad">
      <p class="text-2xl md:text-4xl font-bold">{{ hours }}</p>
      <p class="text-sm md:text-lg">Horas</p>
    </div>
    <div class="text-2xl md:text-4xl font-bold">:</div>
    
    <div class="bg-white text-black p-2 md:p-4 rounded-lg shadow-lg text-center min-w-14 md:min-w-28 txt shad">
      <p class="text-2xl md:text-4xl font-bold">{{ minutes }}</p>
      <p class="text-sm md:text-lg">Minutos</p>
    </div>
    <div class="text-2xl md:text-4xl font-bold">:</div>
    
    <div class="bg-white text-black p-2 md:p-4 rounded-lg shadow-lg text-center min-w-14 md:min-w-28 txt shad">
      <p class="text-2xl md:text-4xl font-bold">{{ seconds }}</p>
      <p class="text-sm md:text-lg">Segundos</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  created() {
    this.calculateCountdown();
    setInterval(this.calculateCountdown, 1000); // Atualiza a cada segundo
  },
  methods: {
    calculateCountdown() {
      const targetDate = new Date("2024-07-13T00:00:00");
      const now = new Date();
      const timeDifference = targetDate - now;

      this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.txt {
  color: #bb966c;
  font-weight: bold;
}

.shad {
  cursor: pointer;
  transition: 0.3s;
  
  &:active, &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transform: translateY(-10px);
  }
}
</style>
