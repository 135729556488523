<template>
    <div>
      <input type="file" @change="onFileChange" /> <hr>
    </div>
    
    <pre>
      {{ categories }} 
    </pre>
  </template>
  
  <script>
  import * as XLSX from "xlsx";
  
  export default {
    data() {
      return {
        arr: [],
        categories: []
      };
    },
    methods: {
      remodeArray(arr) {
        const remodeledArray = [];
        Object.keys(arr).forEach((key) => {
          if (arr[key] !== null && arr[key] !== undefined) {
            remodeledArray.push(arr[key]);
          }
        });
        return remodeledArray;
      },
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheet = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheet];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            this.processExcelData(jsonData);
          };
          reader.readAsArrayBuffer(file);
        }
      },
      processExcelData(data) {
        this.arr = data.map((row, index) => {
          delete row[0]

          if(index == 1) {
            const items = []
            for (let i = 0; i < this.remodeArray(row).length; i++) {
              items.push({
                category: this.remodeArray(row)[i]
              })
            }

            this.categories = items
          }

          if(index % 2 == 0) {

            row.forEach((el, ind) => {
              return console.log(
                el, 
                ind
              );

              this.categories[ind] = { ...this.categories[ind], capa: this.remodeArray(row)[ind]}
            });

            // return console.log(
            //   this.categories
            // );

          } else if(index > 2) {

          }
          // else if(index > 2) {
          //   this.categories.forEach((el, ind) => {
          //     this.categories[ind] = { ...this.categories[ind], name: this.remodeArray(row)[ind]}
          //   });
          // }
        });
        console.log(this.categories);
      },
    },
  };
  </script>
  