<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:grid-cols-3 gap-4 p-3 w-full">

    <div v-for="(item, index) in arr" :key="index" class="flex flex-col items-center p-4 md:p-10">
      <div class="shad w-36 h-36 sm:w-48 sm:h-48 bg-gray-200 flex items-center justify-center rounded-full mx-3">
        <img
          @click="showReport(arr[index])"
          :src="item.image"
          alt="Image 1"
          class="w-full h-full object-cover rounded-full"
        />
      </div>
      <p class="mt-2 text-center">{{ item.name }}</p>
    </div>

    <ModalComponent
      :is-open="showModal"
      :modal-title="currentCategory.name"
      @modalIsClose="closeModal()"
    >
    <template #content>
      <Preview
        :category="currentCategory"
      />
    </template>

    </ModalComponent>

  </div>
</template>

<script setup>
import ModalComponent from './Modals/ModalComponent.vue';
import Preview from './Modals/Preview.vue';
import store from '@/store';
import { onMounted, ref, watch } from 'vue';

const arr = ref([])
let showModal = ref(false)
let currentCategory = ref({})

const showReport = (category) => {
  currentCategory.value = category
  showModal.value = true
}

const closeModal = () => {
  currentCategory.value = {}
  showModal.value = false
}

onMounted(() => {
  store.dispatch('getItems')
})

watch(() => store.getters.itemsArray, (items) => {
  arr.value = items;
});

</script>

<style scoped lang="scss">
.shad {
  cursor: pointer;
  transition: 0.3s;
  
  &:active, &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transform: translateY(-10px);
  }
}
</style>