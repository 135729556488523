<template>
  <div class="relative h bg-fixed bg-center bg-cover img">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
    </div>

    <div class="hidden md:flex md:absolute md:bottom-0 md:left-0 md:transform md:-translate-y-1/2 md:translate-x-0 ml-10">
      <div class="text-white p-4 text-left">
        <h1 class="text-4xl md:text-6xl ">
          EVELLIN & <br> <p class="mt-3">GUSTAVO</p>
        </h1>
        <h4 class="mt-10 text-lg md:text-2xl">
          Capão Bonito, <br> <p class="mt-2">13 de julho de 2024</p>
        </h4>
      </div>
    </div>
    <div class="md:hidden flex items-center justify-center absolute inset-0">
      <div class="text-white p-4 text-left">
        <h1 class="text-4xl md:text-6xl ">
          EVELLIN & <br> <p class="mt-3">GUSTAVO</p>
        </h1>
        <h4 class="mt-4 text-lg md:text-2xl">
          Capão Bonito, <br> <p class="mt-2">13 de julho de 2024</p>
        </h4>
      </div>
    </div>

  </div>
</template>


<script>
export default ({
  name: "ParallaxSection"
});
</script>

<style scoped>
.h {
  height: 90vh !important;
}

h1 {
  font-family: serif !important;
  letter-spacing: 10px;
}
h4 {
  font-family: serif !important;
  letter-spacing: 5px;
}

.img {
  background-image: url('./../assets/bann-2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 50px;
    font-weight: 400;
  }
  .img {
    background-image: url('./../assets/bann-3.png');
    background-position: left;
  }
}

</style>
