<template>
  <div>
    <div class="confetti">
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
    </div>
  </div>
</template>

<style>
.icon {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  position: relative;
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* width: 100%;
  height: 400px; */
  overflow: hidden;
}

.confetti-piece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: #ffd300;
  top: 0;
  opacity: 0;
}
.confetti-piece:nth-child(1) {
  left: 7%;
  transform: rotate(100deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 140ms;
  animation-delay: 140ms;
  -webkit-animation-duration: 1024ms;
  animation-duration: 1024ms;
}
.confetti-piece:nth-child(2) {
  left: 14%;
  transform: rotate(67deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 213ms;
  animation-delay: 213ms;
  -webkit-animation-duration: 1125ms;
  animation-duration: 1125ms;
}
.confetti-piece:nth-child(3) {
  left: 21%;
  transform: rotate(73deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 357ms;
  animation-delay: 357ms;
  -webkit-animation-duration: 1033ms;
  animation-duration: 1033ms;
}
.confetti-piece:nth-child(4) {
  left: 28%;
  transform: rotate(36deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 461ms;
  animation-delay: 461ms;
  -webkit-animation-duration: 814ms;
  animation-duration: 814ms;
}
.confetti-piece:nth-child(5) {
  left: 35%;
  transform: rotate(9deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 15ms;
  animation-delay: 15ms;
  -webkit-animation-duration: 1189ms;
  animation-duration: 1189ms;
}
.confetti-piece:nth-child(6) {
  left: 42%;
  transform: rotate(46deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 323ms;
  animation-delay: 323ms;
  -webkit-animation-duration: 826ms;
  animation-duration: 826ms;
}
.confetti-piece:nth-child(7) {
  left: 49%;
  transform: rotate(-65deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 444ms;
  animation-delay: 444ms;
  -webkit-animation-duration: 828ms;
  animation-duration: 828ms;
}
.confetti-piece:nth-child(8) {
  left: 56%;
  transform: rotate(64deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 8ms;
  animation-delay: 8ms;
  -webkit-animation-duration: 1093ms;
  animation-duration: 1093ms;
}
.confetti-piece:nth-child(9) {
  left: 63%;
  transform: rotate(-62deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 330ms;
  animation-delay: 330ms;
  -webkit-animation-duration: 1036ms;
  animation-duration: 1036ms;
}
.confetti-piece:nth-child(10) {
  left: 70%;
  transform: rotate(67deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 212ms;
  animation-delay: 212ms;
  -webkit-animation-duration: 949ms;
  animation-duration: 949ms;
}
.confetti-piece:nth-child(11) {
  left: 77%;
  transform: rotate(-50deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 444ms;
  animation-delay: 444ms;
  -webkit-animation-duration: 886ms;
  animation-duration: 886ms;
}
.confetti-piece:nth-child(12) {
  left: 84%;
  transform: rotate(69deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 273ms;
  animation-delay: 273ms;
  -webkit-animation-duration: 1115ms;
  animation-duration: 1115ms;
}
.confetti-piece:nth-child(13) {
  left: 91%;
  transform: rotate(40deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 415ms;
  animation-delay: 415ms;
  -webkit-animation-duration: 716ms;
  animation-duration: 716ms;
}

.confetti-piece:nth-child(14) {
  left: 96%;
  transform: rotate(150deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
  -webkit-animation-duration: 716ms;
  animation-duration: 716ms;
}

.confetti-piece:nth-child(15) {
  left: 1%;
  transform: rotate(500deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
  -webkit-animation-duration: 716ms;
  animation-duration: 716ms;
}

.confetti-piece:nth-child(odd) {
  background: #17d3ff;
}
.confetti-piece:nth-child(even) {
  z-index: 1;
}
.confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
}
.confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
  -webkit-animation-duration: 2500ms;
  animation-duration: 2500ms;
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}
.confetti-piece:nth-child(4n-7) {
  background: #ff4e91;
}

@-webkit-keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: translateY(200px);
  }
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: translateY(200px);
  }
}
</style>
