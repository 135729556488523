<template>
  <div class="rounded-md text-2xl bg-personal" :style="{ backgroundImage: `url(${image})` }">
    <div data-aos="fade-up">
      <div class="pr-1 pl-1">
        <h1
          :class="{'animate__animated animate__backInLeft' : isVisible}"
          ref="element"
        >
          <slot />
        </h1>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue'

const props = defineProps({
  image: String
})
const isVisible = ref(false)
const element = ref(null)
let observer = ref(null)

onMounted(() => {
  observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          isVisible.value = true
          observer.unobserve(entry.target)
        }
        console.log(isVisible.value);
      })
    },
    { threshold: 0.1 }
  )

  if (element.value) {
    observer.observe(element.value)
  }
})

onBeforeUnmount(() => {
  if (element.value) {
    observer.disconnect()
  }
})
</script>

<style scoped>
.bg-personal {
  min-height: 900px;
  background-color: #f8f4e3;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
}
</style>
  