import axios from "axios";

const http = axios.create({
  baseURL: 'https://marriage.wolftechti.com.br/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export { http }
